import { createStore } from "solid-js/store";
import type { ICanvasStore } from "~/solidJs/entities/MagicBrushCanvas/helpers/types/ICanvsStore";

export const [getCanvasStore, setCanvasStore] = createStore<ICanvasStore>({
	brushX: 0,
	brushY: 0,
	isBrushVisible: false,
	brushWidth: 10,
	color: "#FFFFFF",
	resizedHeight: 1024,
	resizedWidth: 1024,
	pickedSizeIdx: 0,
	mode: "inpainting",
	paintMode: "paint",
	visible: false
});
