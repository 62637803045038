import { helperCreateObjectUrl, type IFileType, isFileImage } from "~/solidJs/shared/helpers";
import type { imageValidationRules } from "~/solidJs/shared/helpers/types/INodeDefinition";
import { FileValidationError } from "~/solidJs/shared/helpers/CustomErrors/FileValidationError";
import type { Resolution } from "./types";

export const validateImages = async (files: IFileType[], validateRules: imageValidationRules) => {
	const promises = [];
	for (const file of files) {
		promises.push(validateImage(file, validateRules));
	}
	await Promise.allSettled(promises);
};
export const validateImage = async (file: IFileType, validateRules: imageValidationRules) => {
	let width = file.width;
	let height = file.height;
	if (!file.width || !file.height) {
		const { width: addedWidth, height: addedHeight } = await addHeightWidthToFile(file);
		width = addedWidth;
		height = addedHeight;
	}
	if (width! > validateRules.imageMaxWidth) {
		file.valid = false;
		throw new FileValidationError("height", {
			fileParamSize: width!,
			requiredParamSize: validateRules.imageMaxHeight
		});
	}
	if (height! > validateRules.imageMaxHeight) {
		file.valid = false;
		throw new FileValidationError("width", {
			fileParamSize: width!,
			requiredParamSize: validateRules.imageMaxHeight
		});
	}
	file.valid = true;
	return !(
		file.width !== validateRules.imagePreferWidth ||
		file.height !== validateRules.imagePreferHeight
	);
};

export const addHeightWidthToFile = (file: IFileType): Promise<Resolution> | null => {
	if (!isFileImage(file)) return null;
	return new Promise((resolve) => {
		const img = new Image();
		img.onload = () => {
			const height = img.naturalHeight || img.height;
			const width = img.naturalWidth || img.width;
			file.height = height;
			file.width = width;
			resolve({
				width,
				height
			});
		};
		img.src = helperCreateObjectUrl(file);
	});
};
export const getImageDimensionsFromFile = async (file?: IFileType): Promise<Resolution | null> => {
	if (!file) return null;
	if (!isFileImage(file)) return null;
	if (file.height && file.width) {
		return {
			height: file.height,
			width: file.width
		};
	}
	return addHeightWidthToFile(file);
};
export const getRatioFromResolution = (resolution: Resolution): number => {
	return resolution.height / resolution.width;
};
export type imageValidationStatus = "error" | "warning" | "success";
