import { isMobile } from "~/solidJs/shared/helpers/deviceHelpers";

type BuildName = "develop" | "prod";
type IsProduction = "true" | "false";
type Env = "DEV" | "PROD";

export type configType = {
	GRAYLOG_BUILD_NAME: BuildName;
	IS_PRODUCTION: IsProduction;
	SERVER_HOST: string;
	DEFAULT_TIMEOUT: number;
	DEFAULT_CHECK_INTERVAL: number;
	GRAYLOG_HTTP_HOST: string;
	ENV: Env;
	GRAYLOG_PROJECT_NAME: string;
	PHYGC_AI_SERVICE: string;
	FRONTEND_DOMAIN: string;
	PROJECT_VERSION: string;
	SERVER_HOST_NEW: string;
	PHYGC_AI_SERVICE_NEW: string;
	BUILD_TIMESTAMP: number;
	FRONT_CDN_URL?: string;
	FRONT_CANARY?: IsProduction;
  IS_MOBILE?: boolean;
};
export let config: configType;
const requiredKeyes: Array<keyof configType> = [
	"GRAYLOG_BUILD_NAME",
	"IS_PRODUCTION",
	"SERVER_HOST",
	"DEFAULT_TIMEOUT",
	"DEFAULT_CHECK_INTERVAL",
	"GRAYLOG_HTTP_HOST",
	"GRAYLOG_PROJECT_NAME",
	"PHYGC_AI_SERVICE",
	"PROJECT_VERSION",
	"SERVER_HOST_NEW",
	"PHYGC_AI_SERVICE_NEW",
	"BUILD_TIMESTAMP"
] as const;
export async function downloadConfig() {
	try {
		const configFromFile: Omit<configType, "ENV" | "FRONTEND_DOMAIN"> = await (
			await fetch("/public/config.json")
		).json();
		const frontendOrigin = window.location.origin;
		if (!configFromFile) {
			throw "config required";
		}
		for (const key of requiredKeyes) {
			if (!(key in configFromFile)) {
				throw `key ${key} is required`;
			}
		}
		const cdnUrl = import.meta.env.FRONT_CDN_URL;
		config = {
			FRONT_CDN_URL: cdnUrl,
			...configFromFile,
			ENV: configFromFile.IS_PRODUCTION === "true" ? "PROD" : "DEV",
			DEFAULT_TIMEOUT: Number(configFromFile.DEFAULT_TIMEOUT),
			DEFAULT_CHECK_INTERVAL: Number(configFromFile.DEFAULT_CHECK_INTERVAL),
			PHYGC_AI_SERVICE:
				frontendOrigin === "https://app.phygital.plus"
					? configFromFile.PHYGC_AI_SERVICE_NEW
					: configFromFile.PHYGC_AI_SERVICE,
			SERVER_HOST:
				frontendOrigin === "https://app.phygital.plus"
					? configFromFile.SERVER_HOST_NEW
					: configFromFile.SERVER_HOST,
			FRONTEND_DOMAIN: `${frontendOrigin}/`,
			BUILD_TIMESTAMP: Number(configFromFile.BUILD_TIMESTAMP),
			FRONT_CANARY: import.meta.env.FRONT_CANARY,
			IS_MOBILE: isMobile()
		} as configType;
		console.log(config, "CONFIG WAS PULLED FROM LOCAL JSON");
		return config;
	} catch (err: any) {
		console.error(
			`[NETWORK SETTINGS] Failed to pull config from cdn. Default values will be used.`,
			err
		);
		throw err;
	}
}

export function standardHeaders() {
	return {
		Accept: "*/*"
	};
}

export function jsonHeaders() {
	return {
		Accept: "*/*",
		"Content-Type": "application/json"
	};
}

/**
 * Encode an object as url query string parameters
 * - includes the leading "?" prefix
 * - example input — {key: "value", alpha: "beta"}
 * - example output — output "?key=value&alpha=beta"
 * - returns empty string when given an empty object
 */
export function encodeQueryString(obj: Record<string, string | number | string[] | number[]>) {
	const params = new URLSearchParams();

	// Helper function to recursively handle nested objects and arrays
	function flattenObject(
		prefix: string,
		value: string | number | string[] | number[],
		index: number
	) {
		if (Array.isArray(value)) {
			value.forEach((item, index) => {
				flattenObject(`${prefix}[]`, item, index);
			});
		} else {
			params.append(prefix, value + "");
		}
	}

	// Start flattening the object
	Object.keys(obj).forEach((key, index) => {
		flattenObject(key, obj[key], index);
	});

	return "?" + params.toString();
}
