import { axiosClient } from "~/js/network/axiosClient";
import type { IUser, IUserGenerationLimits } from "~/solidJs/shared/helpers/types";
import { BaseApi } from "./baseApi";

export class UserApi extends BaseApi {
	static baseUrl = BaseApi.baseUrl + "users/";
	static supertokensApi = "auth/";

	static async getUserSessionInfo() {
		try {
			const resp = await axiosClient.axiosClient.get<IUser>("/api/users/user_data");
			return resp.data;
		} catch (e) {}
	}
	static async getUserTariffsSessionInfo() {
		try {
			const resp =
				await axiosClient.axiosClient.get<IUserGenerationLimits>("/api/tariffs/me");
			return resp.data;
		} catch (e) {}
	}
	static async getOAuthLink(providerId: string, callbackUrl: string) {
		const { data } = await axiosClient.axiosClient.get<{
			status: string;
			url: string;
		}>(this.supertokensApi + "authorisationurl", {
			params: {
				thirdPartyId: providerId,
				redirectURIOnProviderDashboard: callbackUrl
			}
		});
		return data;
	}
}
