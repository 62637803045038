import { axiosClient } from "~/js/network/axiosClient";
import * as network from "~/js/network/network-handler";
import EventAnalytics from "~/js/utilities/EventAnalytics";
import { BaseApi } from "~/solidJs/shared/api/baseApi";
import { type IFileType, getPrivateMessageFromError } from "~/solidJs/shared/helpers";
import { getMimeTypeByExtension } from "~/js/network/mime-type-handler";
import { blobToFile } from "~/js/network/asset-storage";
import { addHeightWidthToFile } from "../helpers/validateImages";

export type DownloadLinkResponse = {
	download_link: string;
	download_link_proxy: string;
	file_name: string;
};
export type DownloadLinksResponse = DownloadLinkResponse & {
	file_id: number;
};

export class FileApi extends BaseApi {
	static assetsEndpoint = FileApi.baseUrl + "storage/storage-object";
	static checkEndpoint = FileApi.baseUrl + "storage/storage-objects";
	/**
	 * @description get server fileId from File object
	 * @param file
	 */
	static getFileIdInLocalStorage(file: IFileType): null | number {
		console.log(`Try find local storage record of this file (${file.name})`);

		const id = file.fileId;
		if (id === undefined) return null;
		return id;
	}

	static rememberFileInLocalStorage(file: IFileType, id: number) {
		console.log(`Remember local storage record for this file (${file.name})`);
		file.fileId = id;
	}

	static async getLinkToAwsByFileId(id: number, abortSignal?: AbortSignal) {
		const params = {
			obj_id: id
		};
		const url = FileApi.assetsEndpoint + "/download-link";
		const timeStart = Date.now();
		console.log(`GET-request url with query: ${url}`);
		const response = await axiosClient.axiosClient.get<DownloadLinkResponse>(url, {
			params,
			headers: network.standardHeaders(),
			...(abortSignal ? { signal: abortSignal } : {})
		});
		const timeEnd = Date.now();
		EventAnalytics.getFileLink(timeEnd - timeStart, id);
		console.log("Response: ", response);
		if (response.status > 399) {
			throw `Storage Error ${response.status} ${response.statusText}`;
		}
		const urlToAws = response.data;
		if (!urlToAws || !urlToAws["download_link"]) {
			throw `File with ID: ${id} isn't on storage`;
		}
		return urlToAws;
	}

	/**
	 * @description request links list to aws
	 * @param ids
	 * @param abortSignal
	 */
	static async getLinksToAwsListByFileId(ids: number[], abortSignal?: AbortSignal) {
		if (ids.length < 1) return [];
		const url = FileApi.assetsEndpoint + "/v2/download-links";

		const response = await axiosClient.axiosClient.post<{ links: DownloadLinksResponse[] }>(
			url,
			{
				link_ids: ids
			},
			{
				...(abortSignal ? { signal: abortSignal } : {})
			}
		);

		if (response.status > 399) {
			throw `Storage Error ${response.status} ${response.statusText}`;
		}

		return response.data.links;
	}

	/**
	 * @param file {File}  File to push to remote storage
	 * @param abortSignal {AbortSignal} abortSignal
	 * @return {Promise<number>} File storage ID
	 * */
	static async pushFileAsync(file: IFileType, abortSignal?: AbortSignal): Promise<number> {
		try {
			const endPoint = BaseApi.baseUrl + "storage/storage-object";
			console.log("Pushing file to storage...");
			const body = new FormData();
			body.append("fileobject", file, file.name);
			body.append("Content-Type", "multipart/form-data");

			console.log("POST-request body: ", body);

			console.log(`POST-request url: ${endPoint}`);
			const response = await axiosClient.axiosClient.post<{ file_obj_id: number }>(
				endPoint,
				body,
				{
					...(abortSignal ? { signal: abortSignal } : {})
				}
			);

			console.log("Response: ", response);
			if (response.status > 399) {
				throw `Storage Error ${response.status} ${response.statusText}`;
			}
			const responseJson = response.data;
			console.log("Response Json: ", responseJson);
			const id = responseJson.file_obj_id;
			if (!id) {
				console.error(`Server responded with empty storage ID`);
				throw "Server responded with empty storage ID";
			}
			console.log(`File has been pushed to storage. ID = ${id}`);
			this.rememberFileInLocalStorage(file, id);
			return id;
		} catch (err: any) {
			EventAnalytics.sendErrorInfo("error-pushing-file", {
				message: err.message,
				...(err.response ? { statusCode: err.response.status } : {})
			});
			console.error(`Pushing file was failed: ${err}`);
			throw err;
		}
	}

	/**
	 * @param {number} id File ID
	 * @return {Promise<{isStored: bool , fileName: string}>}
	 * */
	static async isFileStoredRemotelyAsync(id: number, abortSignal?: AbortSignal) {
		console.log(`Checking if file (id = ${id}) is stored remotely...`);
		const url = FileApi.checkEndpoint;

		console.log(`GET-request url: ${url}`);
		const response = await axiosClient.axiosClient.get(url, {
			headers: network.standardHeaders(),
			params: { file_obj_id: id },
			signal: abortSignal
		});
		console.log("Response: ", response);
		if (response.status > 399) {
			throw `Storage Error ${response.status} ${response.statusText}`;
		}

		const responseJson = response.data;
		console.log("Response Json: ", responseJson);

		if (responseJson.length < 1) {
			console.log(`Response is empty, so file is NOT stored.`);
			return { isStored: false, fileName: "" };
		}

		const asset = responseJson[0].S3Objects;

		const resolveObj = {
			isStored: asset.status === "on_s3",
			fileName: asset["file_name"]
		};
		console.log(`isStored: ${resolveObj.isStored}, fileName: ${resolveObj.fileName}`);

		return resolveObj;
	}

	/**
	 * @param {File} file File to sync with storage
	 * @return {Promise<number>} File storage ID
	 * */
	static async syncFileWithStorageAsync(file: IFileType, abortSignal?: AbortSignal) {
		let fileId = FileApi.getFileIdInLocalStorage(file);
		if (file.isStoredRemote && fileId) return fileId;
		if (fileId) {
			const check = await FileApi.isFileStoredRemotelyAsync(fileId, abortSignal);
			if (check.isStored) {
				file.isStoredRemote = true;
				return fileId;
			}
		}

		fileId = await FileApi.pushFileAsync(file, abortSignal);
		return fileId;
	}

	/**
	 * @param {File} file File to sync with storage
	 * @return {Promise<IFileType>} File
	 * */
	static async getFileFromAws(
		id: number,
		urlToAws: DownloadLinkResponse,
		abortSignal?: AbortSignal,
		isRetry = false
	): Promise<IFileType> {
		try {
			const queryParams = isRetry ? `&random=${Math.random()}` : "";
			const fileResponseFromAws = await fetch(urlToAws["download_link"] + queryParams, {
				cache: isRetry ? "no-cache" : "default",
				signal: abortSignal,
				headers: {}
			});
			if (fileResponseFromAws.status > 399) {
				throw `Storage Error ${fileResponseFromAws.status} ${fileResponseFromAws.statusText}`;
			}
			const blob = await fileResponseFromAws.blob();

			const extension = urlToAws.file_name.split(".").pop();
			const mimeType = getMimeTypeByExtension(extension);
			const file = blobToFile(blob, urlToAws.file_name, mimeType);

			await addHeightWidthToFile(file);

			FileApi.rememberFileInLocalStorage(file, id);

			return file;
		} catch (e) {
			console.log(e);
			EventAnalytics.sendErrorInfo("error-pulling-file-by-link", {
				message:
					typeof e === "string" ? e : e?.toString?.() || "error-pulling-file-by-link",
				fileId: id,
				url: urlToAws?.download_link,
				fileName: urlToAws?.file_name,
				isRetry: isRetry + ""
			});
			if (!isRetry) {
				return this.getFileFromAws(id, urlToAws, abortSignal, true);
			}
			throw e;
		}
	}

	/**
	 * @description get file object from thirdParty url
	 * @param imageUrl
	 * @returns
	 */
	static async getFileFromImageSrc(imageUrl: string, forcedName?: string, signal?: AbortSignal) {
		try {
			const response = await fetch(imageUrl, {
				headers: {},
				signal
			});
			const blob = await response.blob();
			const url = new URL(imageUrl);
			const fileName = url.pathname.split("/").pop() as string;
			const file = new File([blob], forcedName || fileName, {
				type: blob.type
			});
			await addHeightWidthToFile(file);
			return file;
		} catch (e) {
			console.log(e);
			EventAnalytics.sendErrorInfo("error-pulling-file-by-external-link", {
				message: getPrivateMessageFromError(e) || "error-pulling-file-by-external-link",
				url: imageUrl
			});
			throw e;
		}
	}
}
