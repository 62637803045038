﻿import * as network from "./network-handler";
import EventAnalytics from "~/js/utilities/EventAnalytics";
import { axiosClient } from "~/js/network/axiosClient";

import { FileApi } from "~/solidJs/shared/api/fileApi";

const endPoint = "api/storage/storage-object";

/**
 * @param {string} url Url to fetch
 * @return {File} File
 * */
export async function fetchLocalImageAsFile(url) {
	const blob = await (await fetch(url)).blob();
	const filename = url.split("/").pop();
	const file = new File([blob], filename, { type: blob.type });

	return file;
}

export async function pullAssetViaAws(id, signal) {
	try {
		console.log(`Pulling file (by id = ${id}} from storage...`);

		const urlToAws = await FileApi.getLinkToAwsByFileId(id, signal);

		const file = await FileApi.getFileFromAws(id, urlToAws, signal);

		return file;
	} catch (err) {
		EventAnalytics.sendErrorInfo("error-pulling-file", {
			file_id: id,
			message: err.message,
			...(err.response ? { statusCode: err.response.status } : {})
		});
		console.error(`Pulling file was failed.`, err);
		throw `${err}. If you see this text on DreamBooth node, your model most probably is ready, just go to Stable Diffusion and open my models to see if it's there. If you see it on the other node, just try to relaunch in a sec.`;
	}
}

/**
 * @param {File} file File to push to remote storage
 * @return {Promise<number>} File storage ID
 * */
export async function pushAssetAsync(file) {
	try {
		const id = await FileApi.pushFileAsync(file);
		console.log(`File has been pushed to storage. ID = ${id}`);
		return id;
	} catch (err) {
		console.error(`Pushing file was failed: ${err}`);
		throw err;
	}
}

/**
 * @param {number} id File ID
 * @return {Promise<{isStored: bool , fileName: string}>}
 * */
export async function isFileStoredRemotelyAsync(id) {
	console.log(`Checking if file (id = ${id}) is stored remotely...`);
	const params = { file_obj_id: id };
	const url = network.config.SERVER_HOST + endPoint + "s" + network.encodeQueryString(params);

	console.log(`GET-request url: ${url}`);
	const response = await axiosClient.axiosClient.get(url, {
		headers: network.standardHeaders()
	});
	console.log("Response: ", response);
	if (response.status > 399) {
		throw `Storage Error ${response.status} ${response.statusText}`;
	}

	const responseJson = response.data;
	console.log("Response Json: ", responseJson);

	if (responseJson.length < 1) {
		console.log(`Response is empty, so file is NOT stored.`);
		return { isStored: false, fileName: "" };
	}

	const asset = responseJson[0].S3Objects;

	const resolveObj = {
		isStored: asset.status === "on_s3",
		fileName: asset["file_name"]
	};
	console.log(`isStored: ${resolveObj.isStored}, fileName: ${resolveObj.fileName}`);

	return resolveObj;
}

export function blobToFile(blob, fileName, type) {
	console.log(`Convert blob to file`, { blob, fileName, type });
	return new File([blob], fileName, { lastModified: Date.now(), type });
}
