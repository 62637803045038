import type { Component } from "solid-js";
import { type ComponentProps, splitProps } from "solid-js";
import { type VariantProps, tv } from "tailwind-variants";
const styles = tv(
	{
		base: "outline-none rounded-lg flex gap-2.5 items-center shadow-sm font-normal pointer-events-none",
		defaultVariants: {
			color: "default",
			size: "md"
		},
		variants: {
			size: {
				sm: "py-1 px-2 text-xs",
				md: "py-1 px-2 text-xs",
				lg: "py-2 px-4 text-md"
			},
			color: {
				default: "border-none bg-[#F8F9FB] text-[#2D2D2D]",
				blur: "text-white rounded-full border border-[#C7C7D1] bg-black bg-opacity-40 backdrop-blur-sm",
				premium:
					"rounded-full border border-[var(--primary-purple-80,#BEA3F5)] bg-gradient-to-r from-[rgb(149,112,252)/70] to-[rgb(241,93,254)/70] backdrop-blur-sm text-white",
				lightBlur: "text-white rounded-full border border-white/40 bg-white/30",
				blueGradient:
					"bg-gradient-to-r font-bold from-[#65B6F0] to-[#7775ED] text-white border rounded-full border-[#E6E6E6]",
				grayGradient:
					"rounded-full border border-gray-100 bg-gradient-to-br  from-[#C7C7D1] to-[#66666B] font-bold text-white",
				yellowGradient:
					"rounded-full border border-gray-100 bg-gradient-to-br  from-yellow-300 to-yellow-500 font-bold text-white"
			}
		}
	},
	{ twMerge: true }
);
type TagCustomProps = {
	variant?: TagVariants;
	size?: TagSizes;
};

type TagProps = TagCustomProps & Omit<ComponentProps<"button">, keyof TagCustomProps>;
type TagVariants = VariantProps<typeof styles>["color"];
type TagSizes = VariantProps<typeof styles>["size"];
export const Tag: Component<TagProps> = (props) => {
	const [_, defaultProps] = splitProps(props, ["class", "children", "variant", "size"]);

	return (
		<button
			class={styles({ color: props.variant, class: props.class, size: props.size })}
			{...defaultProps}
		>
			{props.children}
		</button>
	);
};
