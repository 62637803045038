import { TrainNewModelApi } from "~/solidJs/widgets/TrainPageWidgets/TrainNewModelWidget/api/TrainNewModelApi";
import type {
	IMergeItem,
	INodeDefinitionExtended,
	IUniversalSocketType,
	IUniversalSocketTypeExtended,
	groupedSocketUnion
} from "~/solidJs/shared/helpers/types/INodeDefinition";
import { TrainPageApi } from "~/solidJs/entities/TrainPanelEntities/ModelsListEntity/api/TrainPageApi";
import { addToGroup } from "~/solidJs/shared/helpers/groupHelpers";

export const requestAndMergeServiceSchemaAndAirtable = async (
	requestAirtableMerge?: typeof TrainNewModelApi.getAirtableMerge
): Promise<{
	definitions: Record<string, INodeDefinitionExtended>;
	airtableMerge: Record<string, IMergeItem>;
}> => {
	const airtableMerge = await (requestAirtableMerge
		? requestAirtableMerge()
		: TrainNewModelApi.getAirtableMerge());
	const nodeSchemas = await TrainPageApi.getTrainSchemas(Object.keys(airtableMerge));

	const returnDefinitions: Record<string, INodeDefinitionExtended> = {};

	for (const node of nodeSchemas) {
		const globalId = node.node.nodeDefinition.id_global;
		const paramsToRender: Record<string, IUniversalSocketTypeExtended> = {};
		if (!(globalId in airtableMerge)) continue;

		const airtableMergeItem = airtableMerge[globalId];
		const arr = ["inputs", "params", "outputs"] as const;
		const groupToSocket: groupedSocketUnion<IUniversalSocketType> = [];
		for (const [name, airtableSocket] of Object.entries(airtableMergeItem.socketDisplayMerge)) {
			let socketToModify: IUniversalSocketType | undefined;
			let socketIndex: number;
			let foundSocketType: "inputs" | "params" | "outputs";

			outerLoop: for (const socketType of arr) {
				let i = 0;
				for (const socketToCompare of node.node.nodeDefinition[socketType]) {
					if (socketToCompare.name === name) {
						socketIndex = i;
						foundSocketType = socketType;
						socketToModify = socketToCompare;
						if (airtableSocket.group) {
						}
						break outerLoop;
					}
					i++;
				}
			}
			if (socketToModify) {
				socketToModify = {
					...socketToModify,
					...airtableSocket
				};
				if (airtableSocket.isNeedToMoveFromSettings) {
					addToGroup(groupToSocket, socketToModify, airtableSocket.group);
				} else {
					addToGroup(groupToSocket, socketToModify, "Advanced Settings");
				}

				node.node.nodeDefinition[foundSocketType!][socketIndex!] = socketToModify;
				paramsToRender[socketToModify.name] = socketToModify;
			}
		}

		returnDefinitions[globalId] = {
			...node.node.nodeDefinition,
			allSockets: paramsToRender,
			groupedSockets: groupToSocket
		};
	}

	return {
		definitions: returnDefinitions,
		airtableMerge: airtableMerge
	};
};
