import type { RouteDataFuncArgs } from "@solidjs/router";
import { createResource } from "solid-js";
import { fetchTrainSettings } from "~/solidJs/shared/api/fetchTrainSettings";
import { axiosClient } from "~/js/network/axiosClient";
import { requestAndMergeServiceSchemaAndAirtable } from "~/solidJs/widgets/TrainPageWidgets/TrainNewModelWidget/helpers/mergeServiceSchemaAndAirtable";
import type { AuthRouterContext } from "~/solidJs/app/MainApp/models";

export const dataTrainNewModel = (props: RouteDataFuncArgs<AuthRouterContext>) => {
	const settings = createResource<Awaited<ReturnType<typeof fetchTrainSettings>>, boolean>(
		async () => {
			return fetchTrainSettings(axiosClient.axiosClient);
		},
		{ initialValue: { items: [] } }
	);

	const dreamBoothSchema = createResource(() => requestAndMergeServiceSchemaAndAirtable());

	return { settings, dreamBoothSchema, user: props.data.user };
};

export type dataTrainPanelSchemasType = typeof dataTrainNewModel;
