import { axiosClient } from "~/js/network/axiosClient";
import type { IStatusResponse, ITrainPanelModelEl } from "~/solidJs/shared/helpers/types";
import { BaseApi } from "~/solidJs/shared/api/baseApi";
import type {
	INode,
	INodeStartTrainingSchema
} from "~/solidJs/shared/helpers/types/INodeDefinition";
import { serializeArrayQueryParams } from "~/solidJs/shared/helpers";

export class TrainPageApi extends BaseApi {
	static endPoint = TrainPageApi.baseUrl + "train-panel/";
	static modelsListEndpoint = TrainPageApi.endPoint + "task/";
	static statusEndpoint = TrainPageApi.baseUrl + "tasks/queue-position/";

	/**
	 * get json schemas of actual params for dreambooth training
	 */
	static async getTrainSchemas(globalIds: string[]): Promise<INode[]> {
		const idsQuery = serializeArrayQueryParams("node_global_ids", globalIds);
		const { data } = await axiosClient.axiosClient.get<INode[]>(
			TrainPageApi.baseUrl + "nodes/" + idsQuery
		);
		return data;
	}

	/**
	 * get user models list
	 */
	static async getModelsList() {
		const { data } = await axiosClient.axiosClient.get<{ items: ITrainPanelModelEl[] }>(
			TrainPageApi.endPoint
		);
		return data.items;
	}

	/**
	 * get progress info of one model
	 * @param id id of model
	 */
	static async getModelStatus(
		id: ITrainPanelModelEl["id"],
		abortSignal: AbortSignal
	): Promise<IStatusResponse> {
		const { data } = await axiosClient.axiosClient.get<IStatusResponse>(
			TrainPageApi.statusEndpoint + `${id}`,
			{
				...(abortSignal ? { signal: abortSignal } : {})
			}
		);
		return data;
	}

	/**
	 * get temp link to aws for mode file
	 * @param id id of model
	 */
	static async getModelFileLink(id: ITrainPanelModelEl["id"]): Promise<{ link: string }> {
		const { data } = await axiosClient.axiosClient.get<{ link: string }>(
			TrainPageApi.modelsListEndpoint + "/model-file-link" + `/${id}`
		);
		return data;
	}

	/**
	 * delete model record
	 * @param id id of model
	 */
	static async deleteModelRecord(id: ITrainPanelModelEl["id"]) {
		const req = await axiosClient.axiosClient.delete(TrainPageApi.modelsListEndpoint + `${id}`);
		return "sucsess";
	}

	/**
	 * start model training
	 * @param params object of params including dreambooth required params and user metaData
	 */
	static async postModelTraining(
		params: { styleName: string; name: string; category: string } & INodeStartTrainingSchema
	): Promise<{ task_id: ITrainPanelModelEl["id"] }> {
		const { name, styleName, category, ...sockets } = params;
		const { data } = await axiosClient.axiosClient.post<{ task_id: ITrainPanelModelEl["id"] }>(
			TrainPageApi.endPoint,
			sockets,
			{
				params: {
					task_name: name,
					task_type: styleName,
					category
				}
			}
		);
		return data;
	}

	/**
	 * share user model
	 * @param id id of task model belongs to
	 * @param emails array of emails
	 */
	static async postShareModel(id: number, emails: string[]) {
		const { data } = await axiosClient.axiosClient.post(TrainPageApi.endPoint + "share/" + id, {
			emails
		});
		return data;
	}

	/**
	 * get train panel task item by id
	 * @param id id of task
	 */
	static async getParamsSchemaOfTask(id: number) {
		const { data } = await axiosClient.axiosClient.get<{
			data: INodeStartTrainingSchema;
			dreambooth_id: number;
			id_global: string;
		}>(TrainPageApi.endPoint + "task/" + id);
		return data;
	}
}
