export function getCookie(name: string) {
	for (const cookie of document.cookie.split(";")) {
		let [key, value] = cookie.split("=");
		key = key.trim();
		if (key === name) {
			return { key, value };
		}
	}
	return null;
}
