import type { AxiosInstance } from "axios";
import axios from "axios";
import { config } from "~/js/network/network-handler";
import EventAnalytics from "~/js/utilities/EventAnalytics";
import { attemptRefreshingSession } from "supertokens-web-js/recipe/session";
import { setUserStore } from "~/solidJs/entities/HeaderMain/models";
export const axiosClient: { axiosClient: AxiosInstance } = {
	axiosClient: axios.create({})
};
const errorStatusCodesToPass = { 422: true, 401: true, 404: true, 400: true, 418: true };

export const createAxiosClient = () => {
	axiosClient.axiosClient = axios.create({
		baseURL: config.SERVER_HOST,
		withCredentials: true
	});
	axiosClient.axiosClient.interceptors.request.use(
		(requestConfig) => {
			return requestConfig;
		},
		(requestError) => {
			const { config: axiosConfigJson, ...rest } = requestError.toJSON();
			EventAnalytics.sendErrorInfo(
				"client-server-request-error",
				{
					...rest,
					...axiosConfigJson
				},
				false
			);

			return Promise.reject(requestError);
		}
	);
	axiosClient.axiosClient.interceptors.response.use(
		(response) => {
			return response;
		},
		async (responseError) => {
			const { config: axiosConfig } = responseError;
			const { config: axiosConfigJson, ...rest } = responseError.toJSON();
			// Check if the request has already been retried
			const retryCount = axiosConfig.retryCount || 0;
			let retryMax = axiosConfig.retryMax === undefined ? 3 : axiosConfig.retryMax;
			if (responseError?.response?.status === 418) {
				retryMax = 1;
				if (retryCount < retryMax) {
					// Increment the retry count
					axiosConfig.retryCount = retryCount + 1;

					let ok = false;
					try {
						ok = await attemptRefreshingSession();
					} catch (e) {
					}
					if (!ok) {
						setUserStore(undefined);
						return Promise.reject(responseError);
					}
					return axiosClient.axiosClient.request(axiosConfig);
				}
			}

			if (
				responseError.response?.status in errorStatusCodesToPass ||
				responseError.code === "ERR_CANCELED"
			) {
				EventAnalytics.sendErrorInfo(
					"client-server-response-error",
					{
						...rest,
						...axiosConfig
					},
					false
				);
				// For all other errors, reject the promise with the error object
				return Promise.reject(responseError);
			}

			if (retryCount >= retryMax) {
				EventAnalytics.sendErrorInfo(
					"client-server-response-error",
					{
						...rest,
						...axiosConfig
					},
					false
				);
				// Max retry count exceeded, reject the promise with the error object
				return Promise.reject(responseError);
			}

			// Increment the retry count
			axiosConfig.retryCount = retryCount + 1;

			// Delay the request by 200 miliseconds before retrying
			return new Promise((resolve) => {
				setTimeout(() => resolve(axiosClient.axiosClient.request(axiosConfig)), 1000);
			});
		}
	);
};
