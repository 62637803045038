import type { groupedSocketUnion } from "./types/INodeDefinition";
export type groupType<ItemType> = {
	groupName: string;
	items: groupedSocketUnion<ItemType>;
};
export const addToGroup = <itemType extends object>(
	groups: groupedSocketUnion<itemType>,
	socket: itemType,
	groupName?: string
) => {
	if (!groupName) {
		groups.push(socket);
		return;
	}
	const group = findGroup(groups, groupName);
	if ("groupName" in group) {
		group.items.push(socket);
		return;
	}
	group.push({
		groupName,
		items: [socket]
	});
};
export const findGroup = <itemType extends object>(
	groups: groupedSocketUnion<itemType>,
	groupName: string
): groupType<itemType> | groupedSocketUnion<itemType> => {
	let stack = [groups];
	while (stack.length) {
		const newGroups = [];
		const curGroups = stack.pop()!;
		for (const group of curGroups) {
			if (isGroup(group)) {
				if (group.groupName === groupName) return group;
				newGroups.push(group.items);
			}
		}
		stack = newGroups;
	}
	return groups;
};
export const isGroup = (group: object): group is groupType<any> => {
	return "groupName" in group;
};
