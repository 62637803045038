import type { RouteDataFunc } from "@solidjs/router";
import { getUserAndSetStore } from "~/solidJs/shared/helpers/userHelpers";

export const getUser = async () => {
	const user = await getUserAndSetStore();
	return user
};

export const IndexLayoutData: RouteDataFunc = (props) => {
	return { user: getUser() };
};
