import type { Component } from "solid-js";
import { createEffect, createResource, createSignal, type JSX, Show } from "solid-js";
import { getUserStore, setUserStore } from "~/solidJs/entities/HeaderMain/models";
import { useLocation, useNavigate, useRouteData, useSearchParams } from "@solidjs/router";
import {
	type AuthRouterContext,
	routerConfig,
	routerRedirectsConfig,
	accessRedirectMap
} from "~/solidJs/app/MainApp/models";
import { isAuthenticated, isUserHasAccess, isUserNeedToSetupAccount } from "../userHelpers";

type PrivateHocProps = {
	children: JSX.Element;
	accessMap?: Record<string, boolean>;
};

export const PrivateHoc: Component<PrivateHocProps> = (props) => {
	const [isSuccessfulAuthenticated, setIsSuccessfulAuthenticated] = createSignal(false);
	const data = useRouteData<AuthRouterContext>();
	const [userRes] = createResource(() => data.user);
	const navigate = useNavigate();
	const routerLocation = useLocation();
	const authCallback = () => {
		setIsSuccessfulAuthenticated(true);
	};

	const unAuthCallback = () => {
		setIsSuccessfulAuthenticated(false);
		const params = new URLSearchParams({ from: routerLocation.pathname });
		navigate(`${routerConfig.signIn.absolute}?${params}`, { resolve: true });
	};

	const accessLackCallback = () => {
		setIsSuccessfulAuthenticated(false);
		const route = accessRedirectMap[location.pathname];
		if (route) {
			navigate(route, {
				replace: true
			});
			return;
		}
		navigate(routerRedirectsConfig.afterAuth, {
			replace: true
		});
	};

	createEffect(() => {
		const user = getUserStore();
		const isAuth = isAuthenticated(user);
		if (!isAuth) {
			unAuthCallback();
		}
		if (!props.accessMap) {
			authCallback();
			return;
		}
		const hasAccess = isUserHasAccess(user, props.accessMap);
		if (!hasAccess) {
			accessLackCallback();
			return;
		}
		authCallback();
	});
	return (
		<>
			<Show when={userRes() && isSuccessfulAuthenticated()}> {props.children}</Show>
		</>
	);
};

export const SettupedAccountHoc: Component<PrivateHocProps> = (props) => {
	const [isSuccessfulAuthenticated, setIsSuccessfulAuthenticated] = createSignal(false);
	const data = useRouteData<AuthRouterContext>();
	const [userRes] = createResource(() => data.user);
	const navigate = useNavigate();
	const routerLocation = useLocation();
	const authCallback = () => {
		setIsSuccessfulAuthenticated(true);
	};

	const setupAccount = () => {
		const params = new URLSearchParams({ from: routerLocation.pathname });
		navigate(`${routerConfig.setupAccount.absolute}?${params}`, { resolve: true });
	};

	createEffect(() => {
		const user = getUserStore();
		const isAuth = isAuthenticated(user);
		if (!isAuth) {
			return;
		}
		if (isUserNeedToSetupAccount(user)) {
			setupAccount();
			return;
		}
		authCallback();
	});
	return (
		<>
			<Show when={userRes() && isSuccessfulAuthenticated()}> {props.children}</Show>
		</>
	);
};

export const NoSettupedAccountHoc: Component<PrivateHocProps> = (props) => {
	const [isSuccessfulAuthenticated, setIsSuccessfulAuthenticated] = createSignal(false);
	const data = useRouteData<AuthRouterContext>();
	const [userRes] = createResource(() => data.user);
	const navigate = useNavigate();
	const [params] = useSearchParams();
	const authCallback = () => {
		setIsSuccessfulAuthenticated(true);
	};

	const noSetupAccount = () => {
		const fromLocation = params.from;
    navigate(fromLocation || routerConfig.workspace.absolute, { replace: true });
	};

	createEffect(() => {
		const user = getUserStore();
		const isAuth = isAuthenticated(user);
		if (!isAuth) {
			return;
		}
		if (!isUserNeedToSetupAccount(user)) {
			noSetupAccount();
			return;
		}
		authCallback();
	});
	return (
		<>
			<Show when={userRes() && isSuccessfulAuthenticated()}> {props.children}</Show>
		</>
	);
};

export const NoAuthHoc: Component<PrivateHocProps> = (props) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	createEffect(() => {
		const user = getUserStore();
		if (user) {
			const redirectTo = searchParams?.from || routerRedirectsConfig.afterAuth;
			navigate(redirectTo, { replace: true });
		}
	});
	return <Show when={!getUserStore()}> {props.children}</Show>;
};
