import type { JSX } from "solid-js";
import classes from "~/solidJs/entities/SolidModalView/ui/LayoutSolidModalView.module.scss";

type CloseBtnProps = {
	onClick: JSX.CustomEventHandlersCamelCase<HTMLButtonElement>["onClick"];
	class?: string;
};
export const CloseBtn = (props: CloseBtnProps) => (
	<button onclick={props.onClick} class={`${classes.closeBtn} ${props.class || ""}`} />
);
