import * as amplitude from "@amplitude/analytics-browser";
import EventAnalytics from "~/js/utilities/EventAnalytics";
import { getUserStore } from "~/solidJs/entities/HeaderMain/models";
import { getWorkspacesStore } from "~/solidJs/entities/WorkspaceListEntity/models/wrokspaceListContext";
import { getPrivateMessageFromError } from "./errorHelpers";

export const identifyUserSource = (source: string) => {
	const identifyEvent = new amplitude.Identify();
	identifyEvent.set("user_source", source);
	amplitude.identify(identifyEvent, {
		user_id: getUserStore()?.id
	});
};
export const getWorkspaceEventParams = () => {
	const storeValue = getWorkspacesStore();
	return {
		...(storeValue.templateName ? { templateName: storeValue.templateName } : {})
	};
};
const utmsLocalKey = "savedUtms";

const isNeedToSaveUtm = (utm: string): boolean => {
	const variants = ["utm", "ref"];
	for (const variant of variants) {
		if (utm.includes(variant)) {
			return true;
		}
	}
	return false;
};

export const saveUtms = () => {
	try {
		const params = new URLSearchParams(window.location.search);
		const needToSave = [];
		for (const [key, val] of params.entries()) {
			if (!isNeedToSaveUtm(key)) {
				continue;
			}
			needToSave.push([key, val]);
		}
		if (!needToSave.length) {
			return;
		}
		localStorage.setItem(utmsLocalKey, JSON.stringify(needToSave));
	} catch {}
};

export const restoreUtms = (): Record<string, string> => {
	try {
		const utms = localStorage.getItem(utmsLocalKey);
		if (!utms) {
			return {};
		}
		let parsedUtms: [string, string][];
		try {
			parsedUtms = JSON.parse(utms);
		} catch (e) {
			EventAnalytics.sendErrorInfo("error-parse-utms", {
				message: getPrivateMessageFromError(e)
			});
			return {};
		}
		const params: Record<string, string> = {};

		for (const [key, val] of parsedUtms) {
			params[key] = val;
		}

		return params;
	} catch {}
	return {};
};
