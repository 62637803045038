import type { Component } from "solid-js";
import { type JSX, onCleanup } from "solid-js";
import { initSuperTokens } from "~/solidJs/shared/api/auth";
import { setUserStore } from "~/solidJs/entities/HeaderMain/models";

type SuperTokensProps = {
	children: JSX.Element;
};

export const SuperTokens: Component<SuperTokensProps> = (props) => {
	initSuperTokens(
		() => {
			setUserStore(undefined);
		},
		(userInfo) => {
			setUserStore(userInfo);
		}
	);
	return props.children;
};
