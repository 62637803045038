/**
 * @description Codes returned by backend in details -> code key
 * @category Errors
 * @subcategory TaskApiErrors
 */
export enum TaskLimitErrors {
	/**
	 * User exceeded MidJourney limit
	 */
	MidjourneyLimit = "tariff_midjourney_execution_limit",
	/**
	 * User exceeded general task limit
	 */
	GeneralTaskLimit = "tariff_task_execution_limit",
	/**
	 * Deprecated rate limit returned by API provider in backend
	 * @deprecated do not use in new providers
	 *
	 */
	MidjourneyRateLimit = "Rate limit exceeded (1 req/ 1s)."
}

/**
 * @description Codes returned by backend in details -> code key
 * @category Errors
 * @subcategory TaskApiErrors
 */
export enum TaskErrors {
	/**
	 * Error returned by backend while load balancing banned account
	 * @deprecated do not return such an error on frontend
	 */
	InvalidApiKey = "INVALID_API_KEY"
}

/**
 * @description banned task codes
 * @category Errors
 * @subcategory TaskApiErrors
 */
export enum TaskBannedErrors {
	/**
	 * User used banned words in task prompt
	 */
	ObsceneWords = "Obscene words found"
}
