import { createContext, createSignal, type ResourceReturn } from "solid-js";
import type { WorkspaceApi } from "~/solidJs/entities/WorkspaceListEntity/api/WorkspaceApi";
import type { IWorkspaceListItemLight } from "~/solidJs/entities/WorkspaceListEntity/helpers/workspaceTypes";
export type IWorkspaceListContext = {
	workspaceListRes: ResourceReturn<
		Awaited<ReturnType<typeof WorkspaceApi.getWorkspaceLightList>>
	>;
	workspaceClickHandler: (workspace: IWorkspaceListItemLight) => void;
	workspaceDeleteHandler?: (workspace: IWorkspaceListItemLight) => Promise<void>;
};
export type projectsStoreType = {
	activeProjectId: number | null;
	activeProjectName: null | string;
	projectsAmount: number;
	activeWorkspaceLink: string | null;
	templateName?: string;
	isOpenedByLink: boolean;
	isStateSaved: boolean;
	isOpenPending: boolean;
};

export const [getIsWorkspaceModalVisible, setIsWorkspaceModalVisible] = createSignal(false);
export const [getIsWorkspaceLimitModalVisible, setIsWorkspaceLimitModalVisible] =
	createSignal(false);

export const [getWorkspacesStore, setWorkspacesStore] = createSignal<projectsStoreType>({
	activeProjectId: null,
	activeProjectName: null,
	projectsAmount: 0,
	activeWorkspaceLink: null,
	isOpenedByLink: false,
	isStateSaved: true,
	isOpenPending: false
});
export const [getIsAutoSavePending, setIsAutoSavePending] = createSignal(false);

export const WorkspaceListContext = createContext<IWorkspaceListContext>();
