const generics = {
	text: "text",
	image: "image",
	application: "application",
	audio: "audio",
	video: "video",
	object3d: "model",
	object3d_obj: "model"
};

const extensions = {
	png: "png",
	jpg: "jpg",
	jpeg: "jpeg",
	tif: "tif",
	tiff: "tiff",
	svg: "svg",
	mp4: "mp4",
	m4v: "m4v",
	wmv: "wmv",
	avi: "avi",
	mpeg: "mpeg",
	mpg: "mpg",
	glb: "glb",
	gltf: "gltf",
	obj: "obj",
	fbx: "fbx"
};
//IMPORTANT DOESN'T CONTAINS ALL TYPE
export const extensionsByGenerics = {
	image: [".png", ".jpg", ".jpeg", ".webp"],
	image_svg: [".svg"],
	audio: ["mp3", "wav", "ogg"],
	video: [".mp4", "m4v", "wmv", "avi", ".mpeg", ".mpg"],
	object3d: [".glb", ".gltf", ".obj", ".fbx"],
	object3d_obj: [".glb", ".gltf", ".obj", ".fbx"]
};
export const extensionToGeneric = {
	png: "image",
	jpg: "image",
	jpeg: "image",
	webp: "image",
	svg: "image_svg",
	wav: "audio",
	ogg: "audio",
	mp3: "audio",

	mp4: "video",
	mov: "video",

	glb: "object3d",
	gltf: "object3d",
	obj: "object3d",
	fbx: "object3d",
	stl: "object3d",

	safetensor: "binary",
	ckpt: "binary",
	zip: "binary",
	tar: "binary",
	safetensors: "binary"
};

export function getAssetAvailableTypeByExtension(extension) {
	if (extension in extensionToGeneric) {
		return extensionToGeneric[extension];
	}
	return null;
}

export function getMimeTypeByExtension(extension) {
	function constructType(generic, extension) {
		return `${generic}/${extension}`;
	}

	switch (extension) {
		case extensions.glb:
			return constructType(generics.object3d, extensions.glb);
		case extensions.fbx:
			return constructType(generics.object3d, extensions.fbx);
		case extensions.gltf:
			return constructType(generics.object3d, extensions.gltf);
		case extensions.obj:
			return constructType(generics.object3d, extensions.obj);
		case extensions.png:
			return constructType(generics.image, extensions.png);
		case extensions.jpg:
			return constructType(generics.image, extensions.jpg);
		case extensions.jpeg:
			return constructType(generics.image, extensions.jpeg);
		case extensions.tif:
		case extensions.tiff:
			return constructType(generics.image, extensions.tiff);
		case extensions.svg:
			return constructType(generics.image, "svg+xml");
		case extensions.mp4:
		case extensions.m4v:
			return constructType(generics.video, extensions.mp4);
		case extensions.mpeg:
		case extensions.mpg:
			return constructType(generics.video, extensions.mpeg);
		case extensions.wmv:
			return constructType(generics.video, "x-ms-wmv");
		case extensions.avi:
			return constructType(generics.video, "x-msvideo");
		default:
			console.error(`.${extension} extension is unprocessed, return .png`);
			return constructType(generics.image, extensions.png);
	}
}

// unprocessed extensions
/*
types {
    image/gif                             gif;
    image/vnd.wap.wbmp                    wbmp;
    image/x-icon                          ico;
    image/x-jng                           jng;
    image/x-ms-bmp                        bmp;
    image/webp                            webp;

    text/html                             html htm shtml;
    text/sass                              sass;
    text/xml                              xml;
    text/mathml                           mml;
    text/plain                            txt;
    text/vnd.sun.j2me.app-descriptor      jad;
    text/vnd.wap.wml                      wml;
    text/x-component                      htc;
    
    application/x-javascript              js;
    application/atom+xml                  atom;
    application/rss+xml                   rss;
    application/java-archive              jar war ear;
    application/mac-binhex40              hqx;
    application/msword                    doc;
    application/pdf                       pdf;
    application/postscript                ps eps ai;
    application/rtf                       rtf;
    application/vnd.ms-excel              xls;
    application/vnd.ms-powerpoint         ppt;
    application/vnd.wap.wmlc              wmlc;
    application/vnd.google-earth.kml+xml  kml;
    application/vnd.google-earth.kmz      kmz;
    application/x-7z-compressed           7z;
    application/x-cocoa                   cco;
    application/x-java-archive-diff       jardiff;
    application/x-java-jnlp-file          jnlp;
    application/x-makeself                run;
    application/x-perl                    pl pm;
    application/x-pilot                   prc pdb;
    application/x-rar-compressed          rar;
    application/x-redhat-package-manager  rpm;
    application/x-sea                     sea;
    application/x-shockwave-flash         swf;
    application/x-stuffit                 sit;
    application/x-tcl                     tcl tk;
    application/x-x509-ca-cert            der pem crt;
    application/x-xpinstall               xpi;
    application/xhtml+xml                 xhtml;
    application/zip                       zip;

    application/octet-stream              bin exe dll;
    application/octet-stream              deb;
    application/octet-stream              dmg;
    application/octet-stream              eot;
    application/octet-stream              iso img;
    application/octet-stream              msi msp msm;

    audio/midi                            mid midi kar;
    audio/mpeg                            mp3;
    audio/ogg                             ogg;
    audio/x-realaudio                     ra;

    video/3gpp                            3gpp 3gp;
    video/quicktime                       mov;
    video/x-flv                           flv;
    video/x-mng                           mng;
    video/x-ms-asf                        asx asf;
    */
