export class FileValidationError extends Error {
	/*
  width or height
   */
	fileParamSize: number;
	requiredParamSize: number;

	constructor(
		paramName: string,
		props: {
			fileParamSize: number;
			requiredParamSize: number;
		}
	) {
		super(paramName);
		this.fileParamSize = props.fileParamSize;
		this.requiredParamSize = props.requiredParamSize;
		Object.setPrototypeOf(this, FileValidationError.prototype);
	}
}
