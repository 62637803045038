import type { Component } from "solid-js";
import { type ComponentProps, splitProps } from "solid-js";
import { tv, type VariantProps } from "tailwind-variants";
const styles = tv({
	base: "inline-block animate-spin rounded-full border-[2px] border-current border-t-transparent dark:text-white",
	defaultVariants: {
		color: "basic",
		size: "small"
	},
	variants: {
		color: {
			basic: "text-gray-800",
			dark: "text-white"
		},
		size: {
			small: "h-4 w-4",
			medium: "h-6 w-6 border-[3px]",
			large: "h-8 w-8 border-[4px]"
		}
	}
});
export type spinnerVariants = VariantProps<typeof styles>["color"];
export type spinnerSizes = VariantProps<typeof styles>["size"];
type SpinnerOwnProps = {
	variant?: spinnerVariants;
	size?: spinnerSizes;
};

type SpinnerProps = SpinnerOwnProps & Omit<ComponentProps<"div">, keyof SpinnerOwnProps>;

export const Spinner: Component<SpinnerProps> = (props) => {
	const [notNativeAttributes, rest] = splitProps(props, ["class", "variant", "size"]);

	return (
		<div
			class={styles({
				color: props.variant,
				size: props.size,
				class: props.class
			})}
			role={"status"}
			aria-label={"loading"}
			{...rest}
		>
			<span class="sr-only">Loading...</span>
		</div>
	);
};
