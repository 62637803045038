import { BaseApi } from "~/solidJs/shared/api/baseApi";
import { axiosClient } from "~/js/network/axiosClient";
import type {
	IAirtableMergeItem,
	IMergeItem
} from "~/solidJs/shared/helpers/types/INodeDefinition";
import { fetchAirTablePagination } from "~/solidJs/shared/api/fetchAirTablePagination";
import { config } from "~/js/network/network-handler";

export class TrainNewModelApi extends BaseApi {
	static url = TrainNewModelApi.baseUrl + "nodes";

	static async getAirtableMerge() {
		const { records } = await fetchAirTablePagination<IAirtableMergeItem>({
			baseId: "app3F09VAe9P8yfA5",
			tableName: "tblSN3dIgTTzg5sf4",
			view: "Main",
			axiosInstance: axiosClient.axiosClient,
			fieldsToSelect: ["GlobalID", `Sockets_JSON_${config.GRAYLOG_BUILD_NAME}`, "DisplayName"]
		});
		const items: Record<string, IMergeItem> = {};
		for (const record of records) {
			const item = {} as IMergeItem;
			item.globalId = record.fields["GlobalID"];
			item.socketDisplayMerge = JSON.parse(
				record.fields[`Sockets_JSON_${config.GRAYLOG_BUILD_NAME}`]
			);
			item.displayName = record.fields["DisplayName"];
			items[record.fields["GlobalID"]] = item;
		}
		return items;
	}
}
