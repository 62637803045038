import type { RouteDataFuncArgs } from "@solidjs/router";
import type { AuthRouterContext } from "~/solidJs/app/MainApp/models";
import { MagicCanvasApi } from "~/solidJs/shared/api/MagicCanvasApi";
import { queryClient } from "~/solidJs/shared/api/queryClient";

export const dataMagicCanvas = async (props: RouteDataFuncArgs<AuthRouterContext>) => {
	const user = await props.data.user;
	if (!user) {
		return props.data;
	}
	queryClient.prefetchQuery({
		queryFn: MagicCanvasApi.getMagicCavasAirtableAndMerge.bind(MagicCanvasApi),
		queryKey: [MagicCanvasApi.key_getMagicCavasAirtableAndMerge]
	});
	return props.data;
};
