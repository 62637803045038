import { hasOwnProperty } from "./objectHelpers";
import { type ITaskStatuses, TaskStatuses } from "./types/ITrainPanelModelEl";

export const getPrivateMessageFromError = (e: unknown) => {
	let message = "";
	if (e === null) return "Unknown Error";
	if (typeof e === "string") return e;
	if (typeof e !== "object") return "Unknown Error";
	if (hasOwnProperty(e, "stack")) {
		message += e.stack;
	}
	return message + e.toString();
};

export const getPublicMessageFromError = (e: unknown) => {
	const message = "";
	if (e === null) return null;
	if (typeof e === "string") return e;
	if (typeof e !== "object") return null;
	return message + e.toString();
};

export const getMessageAccordTaskStatus = (e: ITaskStatuses | string) => {
	switch (e) {
		case TaskStatuses.Error:
			console.log("Task Status: ERROR");

			return `Please reload the page to make sure you're using the up-to-date version. If the error keeps appearing, use Bug report feature in the right down page corner`;
		case TaskStatuses.Error_params:
			console.log("Task Status: ERROR");
			return `Please reload the page to make sure you're using the up-to-date version. If the error keeps appearing, use Bug report feature in the right down page corner`;

		case TaskStatuses.Canceled:
			console.log("Task Status: CANCELED");
			return `The task was canceled`;

		case TaskStatuses.Error_exec_timeout:
			console.log("Task Status: Error exec timeout");
			return `Node reached out the time limit. Please, check the input images and make sure they are not bigger that 512*512px, or decrease the size of the image you want to generate.`;

		case TaskStatuses.Error_cuda:
			console.error("Task Status: Cuda error");
			return `Can't get memory for processing. May be too many tasks are now run at the same time, just try to relaunch this node in a sec.`;
		default:
			return null;
	}
};

export const isCancelError = (e: unknown) => {
	return typeof e === "object" && "code" in e && e.code === "ERR_CANCELED";
};
