import {
	type Component,
	type ComponentProps,
	For,
	type JSX,
	type Setter,
	Show,
	onCleanup,
	onMount
} from "solid-js";
import classes from "./ImageSlider.module.scss";
import ArrowIcon from "~/Icons/arrow.svg?component-solid";
import { Button } from "../../atoms";

type ImageSliderProps = {
	children: JSX.Element;
	activeIndex: number;
	setActiveIndex: Setter<number>;
	containerProps?: ComponentProps<"div">;
	intervalToMove?: number;
	length: number;
	renderControls?: boolean;
};

export const ImageSlider: Component<ImageSliderProps> = (props) => {
	const increment = () => {
		props.setActiveIndex(props.activeIndex === props.length - 1 ? 0 : props.activeIndex + 1);
	};

	onMount(() => {
		let interval: ReturnType<typeof setInterval>;
		if (props.intervalToMove) {
			interval = setInterval(increment, props.intervalToMove);
		}
		onCleanup(() => {
			clearInterval(interval);
		});
	});

	return (
		<div class={classes.sliderContainer}>
			<div class={classes.contentContainer}>{props.children}</div>
			<div
				class={"flex w-full shrink-0 items-center justify-center gap-2"}
				{...props.containerProps}
			>
				<Show when={props.renderControls}>
					<Button
						onClick={() =>
							props.setActiveIndex((prev) =>
								prev === 0 ? props.length - 1 : prev - 1
							)
						}
						className={"aspect-square w-max p-2"}
					>
						<ArrowIcon
							class={"aspect-square w-4 [&>path]:fill-white [&>path]:!stroke-none"}
						/>
					</Button>
				</Show>
				<For each={Array.from(Array(props.length).keys())}>
					{(el, index) => (
						<button
							class={classes.btn}
							classList={{
								[classes.selectedBtn]: index() === props.activeIndex
							}}
							onClick={() => props.setActiveIndex(index())}
						/>
					)}
				</For>
				<Show when={props.renderControls}>
					<Button
						onClick={() =>
							props.setActiveIndex((prev) =>
								prev === props.length - 1 ? 0 : prev + 1
							)
						}
						className={"aspect-square w-max p-2"}
					>
						<ArrowIcon
							class={
								"aspect-square w-4 rotate-180 [&>path]:fill-white [&>path]:!stroke-none"
							}
						/>
					</Button>
				</Show>
			</div>
		</div>
	);
};
