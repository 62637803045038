import type { Attachment } from "airtable";
import type { AxiosInstance } from "axios";
import { fetchAirTablePagination } from "~/solidJs/shared/api/fetchAirTablePagination";
import type {
	IDataTypeTypes,
	imageValidationRules
} from "~/solidJs/shared/helpers/types/INodeDefinition";

export type ITrainSettingsItem = {
	name: string;
	settingsJSON: Record<string, IDataTypeTypes>;
	settingsJSON_lora: Record<string, IDataTypeTypes>;
	cover: string;
	categoryList: { value: string }[];
	imageValidationRules: imageValidationRules;
};
export type ITrainAirtableBaseItem = {
	trainOnName: string;
	trainOnPicture: Attachment[];
	settingsJSON: string;
	settingsJSON_lora: string;
	categoryList: string[];
	imageValidationRules: string;
};
export const fetchTrainSettings = async (
	axiosInstance: AxiosInstance
): Promise<{ items: ITrainSettingsItem[] }> => {
	const { records } = await fetchAirTablePagination<ITrainAirtableBaseItem>({
		baseId: "app3F09VAe9P8yfA5",
		tableName: "TrainPanelBase",
		view: "Main",
		axiosInstance,
		fieldsToSelect: [
			"trainOnName",
			"trainOnPicture",
			"settingsJSON",
			"categoryList",
			"imageValidationRules",
			"settingsJSON_lora"
		]
	});
	const items: ITrainSettingsItem[] = [];

	for (const record of records) {
		const item = {} as ITrainSettingsItem;
		item.name = record.fields["trainOnName"];
		item.settingsJSON = JSON.parse(record.fields["settingsJSON"]);
		item.settingsJSON_lora = JSON.parse(record.fields["settingsJSON_lora"]);
		item.categoryList = record.fields["categoryList"].map((val) => ({ value: val }));

		item.imageValidationRules = JSON.parse(record.fields["imageValidationRules"]);
		const hoverImagesFromAir = record.fields["trainOnPicture"];
		if (hoverImagesFromAir) {
			item.cover = hoverImagesFromAir[0].url;
		}
		items.push(item);
	}
	return { items };
};
