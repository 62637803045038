import { BaseApi } from "~/solidJs/shared/api/baseApi";
import { axiosClient } from "~/js/network/axiosClient";
import type {
	IWorkspace,
	IWorkspaceListItem,
	IWorkspaceListItemLight,
	IWorkspaceTemplate,
	IWorkspaceWithFiles
} from "~/solidJs/entities/WorkspaceListEntity/helpers/workspaceTypes";

export class WorkspaceApi extends BaseApi {
	static userUrl = BaseApi.baseUrl + "users/";
	static baseUrl = this.userUrl + "workspace";
	static workspaceConfigUrl = this.userUrl + "workspace_config";
	static workspaceTemplateUrl = this.userUrl + "workspace_template";

	/**
	 * @description get workspace list with configs
	 */
	static async getWorkspaceList() {
		const { data } = await axiosClient.axiosClient.get<IWorkspaceListItem[]>(this.baseUrl);
		return data;
	}
	static workspaceListLightKey = "workspaceLightList";
	/**
	 * @description get workspace list without configs
	 */
	static async getWorkspaceLightList() {
		const { data } = await axiosClient.axiosClient.get<IWorkspaceListItemLight[]>(
			this.baseUrl + "/light"
		);
		return data;
	}

	/**
	 * @description get workspace config by id
	 */
	static async getWorkspaceConfigById(id: number | string) {
		const { data } = await axiosClient.axiosClient.get<IWorkspaceListItem>(
			this.baseUrl + `/${id}`
		);
		return data;
	}

	/**
	 * @description old method for creating new workspace without files ids list
	 */
	static async postWorkspace(workspace: IWorkspace, name = "Untitled") {
		const { data } = await axiosClient.axiosClient.post<IWorkspaceListItem>(
			this.baseUrl,
			workspace,
			{
				params: {
					name
				}
			}
		);
		return data;
	}

	/**
	 * @description new method for creating new workspace with files ids list
	 */
	static async postWorkspaceWithFileMove(
		workspaceWithFilesIds: IWorkspaceWithFiles,
		name = "Untitled"
	) {
		const { data } = await axiosClient.axiosClient.post<IWorkspaceListItem>(
			this.baseUrl + "/move",
			workspaceWithFilesIds,
			{
				params: {
					name
				}
			}
		);
		return data;
	}

	/**
	 * @description new method for updating workspace with files ids list
	 */
	static async putWorkspaceWithFileMove(workspaceWithFilesIds: IWorkspaceWithFiles, id: number) {
		const { data } = await axiosClient.axiosClient.post<IWorkspaceListItem>(
			this.workspaceConfigUrl + `/${id}`,
			workspaceWithFilesIds
		);
		return data;
	}

	static async deleteWorkspaceById(id: number) {
		const { data } = await axiosClient.axiosClient.delete(this.baseUrl + `/${id}`);
		return data;
	}

	/**
	 * rename workspace and returns it config
	 * @param id
	 * @param newName
	 * @returns {IWorkspaceListItem}
	 */
	static async renameWorkspaceById(id: number, newName: string) {
		const { data } = await axiosClient.axiosClient.post<IWorkspaceListItem>(
			this.baseUrl + `/${id}` + "/rename",
			{},
			{
				params: {
					name: newName
				}
			}
		);
		return data;
	}

	/**
	 * @description create workspace link. if link already exist, returns an old one
	 * @example returns {workspaceLink: "F8AB0F316CC6A95982C806938A3DE9B0"}
	 */
	static async createWorkspaceLink(workspaceId: number) {
		const { data } = await axiosClient.axiosClient.post<IWorkspaceListItem>(
			this.baseUrl + `/${workspaceId}` + "/share"
		);
		return data;
	}

	/**
	 * @description unshare workspace
	 */
	static async deleteWorkspaceLink(workspaceId: number) {
		const response = await axiosClient.axiosClient.delete(
			this.baseUrl + `/${workspaceId}` + "/share"
		);
	}

	/**
	 * @description get workspace data by link
	 */
	static async getWorkspaceByLink(workspaceUUID: string) {
		const response = await axiosClient.axiosClient.get<IWorkspaceListItem>(
			this.baseUrl + "/shared" + `/${workspaceUUID}`
		);
		return response.data;
	}
	/**
	 * @description returns onboarding template by reserved id
	 */
	static async getOnboardingTemplate(id: number) {
		const response = await axiosClient.axiosClient.get<IWorkspaceTemplate[]>(
			this.workspaceTemplateUrl,
			{
				params: {
					template_id: id
				}
			}
		);

		return response.data[0];
	}
}
