import type { Record } from "airtable";
import type { FieldSet } from "airtable/lib/field_set";
import type { AxiosInstance } from "axios";
import { AirtableProxiApi } from "./AirtableProxyApi";
import { encodeQueryString } from "~/js/network/network-handler";

type fetchAirTableBaseProps = {
	baseId: string;
	tableName: string;
	axiosInstance: AxiosInstance;
};

type fetchAirTablePaginationProps<TFields extends FieldSet> = {
	view: string;
	offset?: string;
	pageSize?: number;
	fieldsToSelect?: Array<keyof TFields>;
	formula?: string;
} & fetchAirTableBaseProps;
type airtableRecord = {
	fields: FieldSet;
};
const airtableBaseIdTableNameToLink = (baseId: string, tableName: string) =>
	`https://api.airtable.com/v0/${baseId}/${tableName}`;

export async function fetchAirTablePagination<TFields extends FieldSet>(
	props: fetchAirTablePaginationProps<TFields>
): Promise<{
	offset?: string;
	records: Record<TFields>[];
}> {
	const {
		baseId,
		tableName,
		view,
		fieldsToSelect = [],
		pageSize,
		formula,
		offset,
		axiosInstance
	} = props;

	const params = {
	...(formula ? { filterByFormula: formula } : {}),
		view,
		...(fieldsToSelect ? { fields: fieldsToSelect as string[] } : {}),
		...(pageSize ? { pageSize } : {}),
		...(offset ? { offset } : {}),
		
	};

	const serializedQuerry = encodeQueryString(params);
	const airtableResp = await AirtableProxiApi.getAirtableCachedResponseByLink<{
		offset?: string;
		records: Record<TFields>[];
	}>(airtableBaseIdTableNameToLink(baseId, tableName) + serializedQuerry);

	return {
		offset: airtableResp.data.offset,
		records: airtableResp.data.records
	};
}

export async function createAirtableRecords(
	props: {
		records: airtableRecord[];
	} & fetchAirTableBaseProps
) {
	const { baseId, tableName, axiosInstance, records } = props;
	const airtableResp = await axiosInstance.post<any>(
		airtableBaseIdTableNameToLink(baseId, tableName),
		{
			records
		},
		{
			headers: {
				authorization:
					"Bearer pati259276SV6LwaB.7637d6cda070ee46455f5a56e178f30128465fea8b2a6c1131a1b1c380405c82"
			},
			withCredentials: false
		}
	);
}
