import type { RouteDataFunc } from "@solidjs/router";
import { thirdPartySignInAndUp } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import EventAnalytics from "~/js/utilities/EventAnalytics";
import { routerRedirectsConfig } from "~/solidJs/app/MainApp/models";
import { getPrivateMessageFromError } from "~/solidJs/shared/helpers";
export const thirdPartySignInAndUpData: RouteDataFunc = async (props) => {
	thirdPartySignInAndUp()
		.then((val) => {
			if (val?.status !== "OK") {
				EventAnalytics.sendErrorInfo("ss_0auth_error", {
					//@ts-ignore
					email: val?.user?.email,
					message: val?.status
				});
			}
		})
		.catch((err) => {
			EventAnalytics.sendErrorInfo("ss_0auth_error", {
				message: getPrivateMessageFromError(err)
			});
		})
		.finally(() => props.navigate(routerRedirectsConfig.afterAuth, { replace: true }));
};
