import type { RouteDataFunc } from "@solidjs/router";
import type { AuthRouterContext } from "~/solidJs/app/MainApp/models";
import { registratePaymentsInAnalitycs } from "~/solidJs/shared/helpers";

const SuccessfulPaymentData: RouteDataFunc<AuthRouterContext> = async (props) => {
	const user = await props.data.user;
	if (!user) {
		return;
	}
	registratePaymentsInAnalitycs();
	return props.data;
};
export default SuccessfulPaymentData;
