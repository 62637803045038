import { axiosClient } from "~/js/network/axiosClient";
import type {
	IAirtableMergeItem,
	IMergeItem
} from "~/solidJs/shared/helpers/types/INodeDefinition";
import { fetchAirTablePagination } from "~/solidJs/shared/api/fetchAirTablePagination";
import { config } from "~/js/network/network-handler";
import type { Attachment } from "airtable";
import { requestAndMergeServiceSchemaAndAirtable } from "~/solidJs/widgets/TrainPageWidgets/TrainNewModelWidget/helpers/mergeServiceSchemaAndAirtable";
type magicCanvasModesHintAirtable = {
	id: string;
	image: Attachment[];
	description: string;
};
type magicCanvasModesHint = {
	id: string;
	image: string;
	description: string;
};
export class MagicCanvasApi {
	static async getMagicCavasAirtableMerge() {
		const { records } = await fetchAirTablePagination<IAirtableMergeItem>({
			baseId: "app3F09VAe9P8yfA5",
			tableName: "MagicCanvasModels",
			view: "Main",
			axiosInstance: axiosClient.axiosClient,
			fieldsToSelect: ["GlobalID", `Sockets_JSON_${config.GRAYLOG_BUILD_NAME}`, "DisplayName"]
		});
		const items: Record<string, IMergeItem> = {};
		for (const record of records) {
			const item = {} as IMergeItem;
			item.globalId = record.fields["GlobalID"];
			item.socketDisplayMerge = JSON.parse(
				record.fields[`Sockets_JSON_${config.GRAYLOG_BUILD_NAME}`]
			);
			item.displayName = record.fields["DisplayName"];
			items[record.fields["GlobalID"]] = item;
		}
		return items;
	}
	static key_getMagicCavasAirtableAndMerge = "key_getMagicCavasAirtableAndMerge";
	static async getMagicCavasAirtableAndMerge() {
		return requestAndMergeServiceSchemaAndAirtable(() =>
			MagicCanvasApi.getMagicCavasAirtableMerge()
		);
	}
	static key_MagicCanvasModesHints = "key_MagicCanvasModesHints";
	static async getMagicCanvasModesHints() {
		const { records } = await fetchAirTablePagination<magicCanvasModesHintAirtable>({
			baseId: "app3F09VAe9P8yfA5",
			tableName: "magicCanvasModes",
			view: "Main",
			axiosInstance: axiosClient.axiosClient
		});
		const items: Record<string, magicCanvasModesHint> = {};
		for (const record of records) {
			const item = {} as magicCanvasModesHint;
			item.id = record.fields.id;
			item.description = record.fields.description;
			if (record.fields.image) {
				item.image = record.fields.image[0].url;
			}
			items[item.id] = item;
		}
		return items;
	}
}
