import SuperTokens from "supertokens-web-js";
import { config } from "~/js/network/network-handler";
import Session from "supertokens-web-js/recipe/session";

import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import EmailVerification from "supertokens-web-js/recipe/emailverification";

import type { IUser } from "~/solidJs/shared/helpers/types";

export function initSuperTokens(
	onUnauthorized: () => void,
	onRefresh: (userInfo: IUser | undefined) => void
) {
	SuperTokens.init({
		appInfo: {
			apiDomain: config.SERVER_HOST,

			appName: config.GRAYLOG_PROJECT_NAME
		},
		recipeList: [
			Session.init({
				tokenTransferMethod: "header",
				onHandleEvent: (event) => {
					if (event?.action === "REFRESH_SESSION") {
					}
					if (event?.action === "UNAUTHORISED") {
						onUnauthorized();
					}
				}
			}),
			ThirdPartyEmailPassword.init({}),
			EmailVerification.init({})
		]
	});
}
