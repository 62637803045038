type PrefixedKeys<T, Prefix extends string> = {
	[K in keyof T as K extends string ? `${Prefix}${K}` : never]: T[K];
};

export function addPrefixToKeys<T extends Record<string, any>, P extends string>(
	obj: T,
	prefix: P
): PrefixedKeys<T, P> {
	const prefixedObj = {} as PrefixedKeys<typeof obj, typeof prefix>;

	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			// @ts-ignore
			prefixedObj[`${prefix}${key}`] = obj[key];
		}
	}

	return prefixedObj;
}
