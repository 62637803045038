import { type Component, type JSX, Show } from "solid-js";
import classes from "./TrainPanelModal.module.scss";
import { CloseBtn } from "~/solidJs/entities/SolidModalView/ui/CloseBtn";

type TrainPanelModalProps = {
	HeaderTitle?: JSX.Element;
	HeaderSubtitle?: JSX.Element;
	Buttons: JSX.Element[];
	children?: JSX.Element;
	containerClassName?: string;
	toggleSelf?: (isShown: boolean) => void;
};

export const TrainPanelModal: Component<TrainPanelModalProps> = (props) => {
	return (
		<article
			class={`${classes.modalContainer} ${props.containerClassName || ""}`}
			onclick={(e) => e.stopPropagation()}
		>
			<CloseBtn
				onClick={() => {
					props.toggleSelf?.(false);
				}}
			/>
			<div class={classes.headerContainer}>
				{props.HeaderTitle}
				{props.HeaderSubtitle}
			</div>
			<Show when={props.children}>
				<div class={classes.contentContainer}>{props.children}</div>
			</Show>

			<div class={classes.buttonContainer}>{props.Buttons}</div>
		</article>
	);
};
