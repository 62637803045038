import { type Accessor, type Setter, createSignal } from "solid-js";

class SessionCounter {
	increased = false;
	count: undefined | number;
	timeCounter: typeof TimeCounter;
	constructor(timeCounter: typeof TimeCounter) {
		this.getCurSessionCount();
		this.timeCounter = timeCounter;
	}

	increaseSessionCount() {
		this.count ? (this.count += 1) : (this.count = 1);
		localStorage.setItem(
			"secondSessionQuestions",
			JSON.stringify({
				sessionCount: this.count
			})
		);
	}

	getCurSessionCount() {
		const count = this.count;
		if (count !== undefined) return count;
		const countStorage = localStorage.getItem("secondSessionQuestions");
		if (countStorage === null) {
			this.count = 0;
			return 0;
		}
		try {
			const countJson = JSON.parse(countStorage);
			const newVal: number =
				countJson?.sessionCount === "passed" ? 3 : countJson.sessionCount || 0;
			this.count = newVal;
			return this.count;
		} catch (e) {
			this.count = 0;
			return 0;
		}
	}
}
type prefixStore<T extends readonly string[]> = {
	[Key in T[number]]: {
		getValue: Accessor<number>;
		setValue: Setter<number>;
	};
};
export class GenerationCounter<T extends readonly string[]> {
	inited = false;
	curGenerationCount: number;
	prefixes: prefixStore<T>;
	constructor(prefixes: T, generationCount: number) {
		const deserialized = this.deSerializePrefixes(prefixes);
		const prefixesStore: prefixStore<T> = {} as prefixStore<T>;
		for (const key of prefixes) {
			let val = 0;
			if (key in deserialized) {
				val = deserialized[key];
			}
			const [get, set] = createSignal(val);
			//@ts-ignore
			prefixesStore[key] = {
				getValue: get,
				setValue: set
			};
		}
		this.curGenerationCount = generationCount;
		this.prefixes = prefixesStore;
		this.serializePrefixes();
	}
	serializePrefixes() {
		for (const [key, value] of Object.entries(this.prefixes as prefixStore<string[]>)) {
			localStorage.setItem(key, value.getValue() + "");
		}
	}
	deSerializePrefixes(prefixes: T) {
		const deserialized: Record<string, number> = {};
		for (const key of prefixes) {
			const number = localStorage.getItem(key);
			if (!number || isNaN(Number(number))) continue;
			deserialized[key] = Number(number);
		}
		return deserialized;
	}
	getPrefix(prefixKey: T[number]) {
		return this.prefixes[prefixKey];
	}
	updatePrefixes(generationCount: number) {
		const diff = generationCount - this.curGenerationCount;
		if (diff === 0) return;
		this.curGenerationCount = generationCount;
		for (const [key, value] of Object.entries(this.prefixes as prefixStore<string[]>)) {
			const newVal = value.setValue((prev) => prev + diff);
			localStorage.setItem(key, newVal + "");
		}
	}
	setCurCounter(generationCount: number) {
		this.curGenerationCount = generationCount;
		this.inited = true;
	}
}
class TimeCounter {
	static initTs: number = Date.now();
	static callbacs: Record<number, (() => void)[]> = {};
	/**
	 * @description add a callback that will be triggered when the user spends a certain amount of time in the app
	 */
	static addCallbackToTime(timeToSpend: number, callback: () => void) {
		const diff = Date.now() - this.initTs;
		if (diff >= timeToSpend) {
			callback();
			return;
		}
		setTimeout(callback, timeToSpend - diff);
	}
}
export const sessionCounter = new SessionCounter(TimeCounter);
