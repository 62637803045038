export type primitives = number | string | boolean | null | undefined;

export const foundDiffBetweenTwoPrimitiveObjects = (
	obj1: Record<string, primitives>,
	obj2: Record<string, primitives>
) => {
	const diff: [key: string, value: primitives][] = [];

	for (const key in obj1) {
		if (obj1[key] !== obj2[key]) {
			diff.push([key, obj2[key]]);
		}
	}
	for (const key in obj2) {
		if (!(key in obj1)) {
			diff.push([key, obj2[key]]);
		}
	}
	return diff;
};

export function hasOwnProperty<T, K extends PropertyKey>(
	obj: T,
	prop: K
): obj is T & Record<K, unknown> {
	return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function valueOrDefault<T extends object>(obj: Partial<T>, defualt: T): T {
	const res: T = { ...defualt };

	for (const key of Object.keys(obj)) {
		res[key] = obj[key];
	}

	return res;
}
