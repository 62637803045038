import type { ITaskSocket } from "~/solidJs/shared/helpers/types/INodeDefinition";
import type { HistoryResponseListItem } from "~/solidJs/entities/HistoryListEntity/api/HistoryApi";

export type ITaskStatuses =
	| "error"
	| "running"
	| "done"
	| "canceled"
	| "new"
	| "preparing"
	| "pending"
	| "error_exec_timeout"
	| "error_cuda"
	| "error_params"
	| "waiting_for_launch";
export const TaskStatuses = {
	New: "new",
	Preparing: "preparing",
	Pending: "pending",
	Running: "running",
	WaitingForLaunch: "waiting_for_launch",
	Done: "done",
	Error: "error",
	Canceled: "canceled",
	Error_exec_timeout: "error_exec_timeout",
	Error_cuda: "error_cuda",
	Error_params: "error_params"
} satisfies Record<string, ITaskStatuses>;
Object.freeze(TaskStatuses);
export type IDeleteStatus = "success";

export type ITrainPanelModelEl = {
	id: number;
	name: string;
	/**
	 * style name from task_type of create task endpoint
	 */
	type: string;
	method: string;
	base_model: string;
	category: string;
	created_at: string;
	progress?: number;
	model_link?: string;
	/**
	 * array of files ids used for this model
	 */
	input_images: number[];
	is_blocked: boolean;
	status: ITaskStatuses;
};
export type ITaskDoneStatusResponse = Omit<ITaskStatusResponse, "status" | "outputs"> & {
	status: "done";
	historyItem: HistoryResponseListItem;
	outputs: ITaskSocket[];
};
export type ITaskStatusResponse = {
	status: Exclude<ITaskStatuses, "done">;
	position: number;
	outputs: [];
	progress: null | number;
};
export type ITaskStatusResponseExternal = ITaskStatusResponse & {
	error_message?: string;
	start_at?: number;
	end_at?: number;
};
export type ITaskDoneStatusResponseExternal = Omit<ITaskStatusResponseExternal, "status"> & {
	status: "done";
	outputs: ITaskSocket[];
};
export type IStatusResponse = ITaskStatusResponse | DoneResponse;
export type DoneResponse = ITaskDoneStatusResponse | ITaskDoneStatusResponseExternal;
export type IStartTrainingResponse = { task_id: number };
