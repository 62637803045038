import type { RouteDataFuncArgs } from "@solidjs/router";
import { createResource } from "solid-js";
import type { ITrainPanelModelEl } from "~/solidJs/shared/helpers/types";
import { TrainPageApi } from "~/solidJs/entities/TrainPanelEntities/ModelsListEntity/api/TrainPageApi";
import type { dataTrainPanelSchemasType } from "~/solidJs/pages/(TrainPanelHomeLayout)/api/dataTrainPanelSchemas";
import type { AuthRouterContext } from "~/solidJs/app/MainApp/models";

export const dataTrainPanelHomePage = (
	props: RouteDataFuncArgs<AuthRouterContext & dataTrainPanelSchemasType>
) => {
	const dataTrainPanelHomeListRes = createResource<ITrainPanelModelEl[]>(
		TrainPageApi.getModelsList
	);
	return {
		dataTrainPanelHomeListRes,
		dreamBoothSchema: props.data.dreamBoothSchema,
		settings: props.data.settings,
		user: props.data.user
	};
};
export type dataTrainPanelHomePageType = typeof dataTrainPanelHomePage;
