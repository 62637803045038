import { config } from "~/js/network/network-handler";
import { type DownloadLinksResponse, FileApi } from "../api/fileApi";
import EventAnalytics from "~/js/utilities/EventAnalytics";

export type IFileCustomFields = {
	objectUrl?: string;
	height?: number;
	width?: number;
	valid?: boolean;
	optionalName?: string;
	fileId?: number;
	isStoredRemote?: boolean;
};

export type IFileType = File & IFileCustomFields;

export const getFileSrcToCdnOrRelative = (fileName: string) => {
	const prefix = "public/" + fileName;
	if (config.FRONT_CDN_URL) {
		return config.FRONT_CDN_URL + prefix;
	}
	return window.location.origin + "/" + prefix;
};

export const helperCreateObjectUrl = (file: IFileType) => {
	if (file.objectUrl) {
		return file.objectUrl;
	}
	const url = URL.createObjectURL(file);
	file.objectUrl = url;
	return url;
};

export const helperRevokeObjectURL = (file: IFileType) => {
	if (file?.objectUrl) {
		URL.revokeObjectURL(file.objectUrl);
		delete file.objectUrl;
	}
};

export const downloadAsset = (file: IFileType) => {
	const a = document.createElement("a");
	document.body.append(a);
	a.setAttribute("blank", "_target");

	a.href = helperCreateObjectUrl(file);

	a.download = file.optionalName
		? `${file.optionalName}.${file.name.split(".").pop()}`
		: file.name;
	a.click();
	a.remove();
};

export const downloadAssetFromDataUrl = (url: string, name = "canvas.png") => {
	const a = document.createElement("a");

	a.href = url;
	a.download = name;
	a.click();
};

/**
 * @description return map fileId to DownloadLinksResponse
 * @param links
 * @param customMap if custom map is pased will mutate it
 * @returns
 */
const mapIdListToLinkResponse = (links: DownloadLinksResponse[], customMap?: fileIdsMap) => {
	const map: fileIdsMap = customMap || {};
	for (const linkItem of links) {
		map[linkItem.file_id] = linkItem;
	}
	return map;
};

/**
 * @description get links list by file ids and return map from it Map<id, DownloadLinksResponse>
 * @param filesIdsList
 * @returns
 */
export const getLinksAndMap = async (filesIdsList: number[]) => {
	const map: fileIdsMap = {};
	if (filesIdsList.length === 0) return map;
	try {
		const linksList = await FileApi.getLinksToAwsListByFileId(filesIdsList);
		mapIdListToLinkResponse(linksList, map);
	} catch (e: any) {
		console.log(e);
		EventAnalytics.sendErrorInfo("get-files-ids-list-map", {
			message: e?.toString?.() || e?.message || "get-files-ids-list-map"
		});
	}

	return map;
};

export type fileIdsMap = Record<number, DownloadLinksResponse>;

/**
 * @description sync file with remote storage and extract file name or fileSrc based on type
 * @param file
 * @returns
 */
export const getFileData = async (
	file: File,
	abortSignal?: AbortSignal
): Promise<{ fileName: string; fileId: number } | { fileSrc: string; fileId: number }> => {
	const fileId = await FileApi.syncFileWithStorageAsync(file, abortSignal);
	if (file.type === "application/pdf") {
		return { fileName: file.name, fileId };
	}
	const url = await FileApi.getLinkToAwsByFileId(fileId, abortSignal);

	return { fileSrc: url.download_link, fileId };
};

/**
 * @description extract file name or file url from file
 * @param file
 * @returns
 */
export const getFileLocalData = (file: File): { fileName: string } | { fileSrc: string } => {
	if (file.type === "application/pdf") {
		return { fileName: file.name };
	}

	return { fileSrc: helperCreateObjectUrl(file) };
};

/**
 * @description check if file is image
 * @param file
 * @returns
 */
export const isFileImage = (file: IFileType): boolean => {
	return file["type"].split("/")[0] === "image";
};

export const handlePasteImageEvent = (event: ClipboardEvent) => {
	const items = event.clipboardData;
	if (!items?.files) {
		return null;
	}
	return Array.from(items.files);
};

export async function setClipboard(text: string) {
	const type = "text/plain";
	const blob = new Blob([text], { type });
	const data = [new ClipboardItem({ [type]: blob })];
	await navigator.clipboard.write(data);
}
